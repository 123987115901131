<template>
    <KCourse loader-class="MBcont" course-id="2" title="Анатомия тела" :items="items">
        <div class="MBtextcont">

            <p class='nomargin'>Нам понадобятся: </p>
            <ul>
                <li>Картон</li>
                <li>Калька</li>
                <li>Карандаш</li>
                <li>Клей для бумаги</li>
                <li>Макетный нож</li>
                <li>Чертеж</li>
            </ul>
            <p class="nomargin"><a class="a-download" href="/img/files/Anatomy_part1.jpg">Скачать чертеж. Часть 1</a></p>
            <p class="nomargin"><a class="a-download" href="/img/files/Anatomy_part2.jpg">Скачать чертеж. Часть 2</a></p>
            
        </div>

        <VideoView video-id="c997604282e943b0bcf8ae3d24bb68cb"/>

        <div class="MBtextcont">
            <p class='nomargin'><b>Задание:</b></p>
            <ol>
                <li>Распечатать чертеж на двух листах формата А4, склеить.</li>
                <li>Сделать трафареты для заготовок: головы, тела, ноги, руки — в двух ракурсах вид спереди и сбоку.</li>
            </ol>
        </div>
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
        },
        data() {
        return {
            items: [
                { title: "Готовим трафареты", url:"/mycourses/body/1"},
                { title: "Заготовки из пластилина", url:"/mycourses/body/2"},
                { title: "Подготовка к обтяжке", url:"/mycourses/body/3"},
                { title: "Обтяжка пластиком", url:"/mycourses/body/4"},
                { title: "Достаем пластилин", url:"/mycourses/body/5"},
                { title: "Основа кисти", url:"/mycourses/body/6"},
                { title: "Основа стопы", url:"/mycourses/body/7"},
                { title: "Анатомия руки", url:"/mycourses/body/8"},
                { title: "Анатомия кисти", url:"/mycourses/body/9"},
                { title: "Анатомия ноги", url:"/mycourses/body/10"},
                { title: "Анатомия стоп", url:"/mycourses/body/11"},
                { title: "Анатомия тела", url:"/mycourses/body/12"},
            ],
        }
        }
    }
</script>